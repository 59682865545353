/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

const $ = window.Bliss,
  $$ = $.$

export class ToggleElements {
  constructor({
    singleOpen = true,
    checkInitialHash = true,
    updateHash = true,
  } = {}) {
    $.delegate(
      document.body,
      'click',
      '[data-toggle-trigger]',
      this.handleElementClick
    )

    this.singleOpen = singleOpen
    if (!singleOpen && updateHash) {
      console.warn('singleOpen must be true to use updateHash')
    }
    this.updateHash = singleOpen && updateHash

    window.addEventListener('resize', this.handleResize)
    window.addEventListener('load', this.handleLoad)

    if (checkInitialHash && window.location.hash) {
      let slug = window.location.hash.substring(1)
      let faq = document.querySelector(`[data-hash="${slug}"]`)

      if (faq && !this.isOpen(faq)) {
        this.toggleElement(faq)
      }
    }
  }

  // fix sizes and positions on resize
  handleResize = () => {
    $$('[data-toggle-callback-on-resize]').forEach(el => {
      let callbackScope =
        el.closest(el.getAttribute('data-toggle-callback-scope')) ||
        $(el.getAttribute('data-toggle-callback-scope'))
      this[el.getAttribute('data-toggle-callback')].call(
        callbackScope,
        el.getAttribute('data-toggle-class') || 'toggled'
      )
    })
  }

  // update height e.g. after images are done loading
  handleLoad = () => {
    $$('data-toggle-callback-on-load').forEach(el => {
      let callbackScope =
        el.closest(el.getAttribute('data-toggle-callback-scope')) ||
        $(el.getAttribute('data-toggle-callback-scope'))
      this[el.getAttribute('data-toggle-callback')].call(
        callbackScope,
        el.getAttribute('data-toggle-class') || 'toggled'
      )
    })
  }

  handleElementClick = e => {
    e.preventDefault()
    let target = e.target && e.target.closest('[data-toggle-element]')

    if (target) {
      // if we only allow one item to be opened
      // then close the others if we are closed
      if (this.singleOpen) {
        let openOtherElements = $$(
          `[data-toggle-element].${target.getAttribute('data-toggle-class') ||
            'toggled'}`
        )

        openOtherElements.forEach(el => {
          if (el != target.closest('[data-toggle-element]')) {
            this.toggleElement(el)
          }
        })
      }

      this.toggleElement(target)

      /*     if (this.updateHash) {
        if (this.isOpen(target)) {
          window.location.hash = target.dataset.hash
        } else {
          window.location.hash = ''
        }
      }*/
    }
  }

  toggleElement = target => {
    let el = target.closest('[data-toggle-element]')
    el.classList.toggle(target.getAttribute('data-toggle-class') || 'toggled')

    // call given callback with scope if set. first search scope with 'closest', if none found search globally
    if (
      target.hasAttribute('data-toggle-callback') &&
      target.hasAttribute('data-toggle-callback-scope')
    ) {
      let callbackScope =
        target.closest(target.getAttribute('data-toggle-callback-scope')) ||
        $(target.getAttribute('data-toggle-callback-scope'))
      this[target.getAttribute('data-toggle-callback')].call(
        callbackScope,
        target.getAttribute('data-toggle-class') || 'toggled'
      )
    }
  }

  isOpen = target => {
    let el = target.closest('[data-toggle-element]')
    return el.classList.contains(
      target.getAttribute('data-toggle-class') || 'toggled'
    )
  }

  foldoutSetSize(toggleClass) {
    if (!this) return

    let $wrapper = $('.js-foldout-wrapper', this)
    let $body = $('.js-foldout-body', this)

    if (!$wrapper || !$body) return

    if (this.classList.contains(toggleClass)) {
      $.style($wrapper, {height: $body.offsetHeight + 'px'})
    } else {
      $.style($wrapper, {height: '0px'})
    }
  }
}
