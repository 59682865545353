const {$, $$} = window // eslint-disable-line

export function initIntersectionObserver() {
  let observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        document.body.classList.toggle(
          entry.target.dataset.intersectionBodyClass,
          entry.isIntersecting
        )
      })
    },
    {
      rootMargin: '-100px',
    }
  )
  $$('[data-intersection-body-class]').forEach(element =>
    observer.observe(element)
  )
}
