const {$, $$} = window // eslint-disable-line

export class Masonry {
  constructor(container, cellSelector) {
    this.container = container
    this.cellSelector = cellSelector

    this.addEventListeners()
  }

  resizeGridItems = () => {
    let rowStyle = getComputedStyle(this.container)
    let rowGap = parseInt(rowStyle.gridRowGap)
    let rowHeight = parseInt(rowStyle.gridAutoRows) + rowGap
    $$(this.cellSelector, this.container).forEach(el => {
      let rowSpan = Math.ceil(
        (el.firstElementChild.getBoundingClientRect().height + rowGap) /
          rowHeight
      )

      el.style.gridRowEnd = 'span ' + rowSpan
    })
  }

  addEventListeners = () => {
    document.addEventListener('DOMContentLoaded', this.resizeGridItems)
    window.addEventListener('load', this.resizeGridItems)
    window.addEventListener('resize', () => {
      this.resizeGridItems()
    })
  }
}
