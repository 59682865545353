/* eslint-disable */

const {$, $$} = window // eslint-disable-line

document.addEventListener('DOMContentLoaded', function() {
  /* Home Video Toggle */
  let tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/player_api'
  let firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

  let tv
  // playerDefaults = {
  //   allowfullscreen: 1,
  //   modestbranding: 0,
  //   rel: 0,
  //   showinfo: 0,
  //
  //   disablekb: 1,
  //   enablejsapi: 0,
  //   iv_load_policy: 3,
  //   autoplay: 1,
  //   loop: 1,
  // }
  // let vid = [{videoId: 'JaKSiOD8iZA', suggestedQuality: 'hd720'}]

  // let currVid = 0
  function onYouTubePlayerAPIReady() {
    tv = new YT.Player('tv', {
      videoId: 'E7_4Og00SII',
      events: {onReady: onPlayerReady},
      playerVars: {
        autoplay: 1,
        disablekb: 1,
        controls: 0,
        rel: 0,
        fs: 0,
        modestbranding: 0,
        showinfo: 0,
        // version: 3,
        loop: 1,
        playlist: 'E7_4Og00SII',
        playsinline: 1,
        // start: 10,
      },
    })
  }

  window.onYouTubePlayerAPIReady = onYouTubePlayerAPIReady

  function onPlayerReady(event) {
    event.target.playVideo()
    tv.mute()
  }

  function vidRescale() {
    var w = $(window).width() + 200,
      h = $(window).height() + 200

    if (w / h > 16 / 9) {
      tv.setSize(w, w / 16 * 9)
      $('.tv .screen').css({left: '0px'})
    } else {
      tv.setSize(h / 9 * 16, h)
      $('.home-video__background').css({
        left: -($('.home-video__background').outerWidth() - w) / 2,
      })
    }
  }

  window.addEventListener('load resize', function() {
    vidRescale()
  })

  $('#button-play').on('click', function(event) {
    event.preventDefault ? event.preventDefault() : (event.returnValue = false)
    $('#tv').toggleClass('mute')
    $('[data-home-video]').toggleClass('show-video')

    if ($('#tv').hasClass('mute')) {
      tv.mute()
    } else {
      tv.unMute()
    }
  })
  $('[data-home-video-toggle]').on('click', function(event) {
    event.preventDefault ? event.preventDefault() : (event.returnValue = false)
    $('#tv').toggleClass('mute')
    $('[data-home-video]').toggleClass('show-video')
    if ($('#tv').hasClass('mute')) {
      tv.mute()
    } else {
      tv.unMute()
    }
  })
})
