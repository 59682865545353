import './main.scss'

import _throttle from 'lodash/throttle'
import '@babel/polyfill'
import 'intersection-observer'

import 'blissfuljs'
import 'flickity'
const $ = window.Bliss,
  $$ = $.$

import {initIntersectionObserver} from './js/intersection'
import {Accordion} from './js/accordion'
import {ToggleElements} from './js/toggleElements'
import scrollmenu from './js/scrollmenu'
import {Timeline} from 'js/timeline'
import {Masonry} from './js/masonry'
import {Gallery} from 'js/gallery'
import 'js/yt-player.js'

$.ready().then(function() {
  initIntersectionObserver()

  /*
 * Gallery
 * =======
 */
  const sliders = $$('.gallery-slide:not(.gallery--none)')
  sliders.forEach(el => {
    new Gallery(el, {
      interval: 5000,
      autoPlay: true,
      createThumbs: true,
      endless: false,
    })
  })

  new ToggleElements()

  let timeline = $('.timeline__list')
  let getEventDates = $$('.timeline__date')

  if (timeline) {
    new Timeline(timeline, getEventDates)
  }

  // Menu Toggle

  let navArrow = $$('.navigation__item-arrow')
  navArrow.forEach(function(element) {
    element.addEventListener('click', function() {
      let nextSubmenu = element.parentNode.parentNode.querySelector(
        '.navigation__submenu'
      )
      nextSubmenu.classList.toggle('is--open')
      element.classList.toggle('rotate')
    })
  })

  $$('[data-accordion-id]').forEach(el => new Accordion(el))

  // $$('form').forEach(form => new AjaxForm(form))

  /*
  *  Scroll Menu
  */
  new scrollmenu(['.navigation a', '[data-scroll-anchor]'])

  /*
  *  Mobile Nav Toggle
  */
  $('[data-toggle-mobile-nav]').addEventListener('click', e => {
    e.preventDefault()
    document.body.classList.toggle('nav-is-open')
  })

  /*
  * Close navigation after a click on a link
  */
  let navigation = $('.navigation')
  $.delegate(navigation, 'click', '.navigation__link', () => {
    document.body.classList.remove('nav-is-open')
  })

  $$('[data-masonry]').forEach(
    el => new Masonry(el, el.getAttribute('data-masonry'))
  )

  /*
  *   Set scroll indicator
  */

  let scrolledThreshold = 20

  window.addEventListener(
    'scroll',
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add('scrolled')
      } else {
        document.body.classList.remove('scrolled')
      }
    }, 100)
  )

  /*
  *   Intersection Observer
  */

  // let homeVideoContainer = $('[data-home-video]')
  //
  // if (homeVideoContainer) {
  //   let button = $$('[data-home-video-toggle]', homeVideoContainer)
  //   let buttonPlay = $('[data-home-video-play]', homeVideoContainer)
  //   button.forEach(function(el) {
  //     el.addEventListener('click', event => {
  //       event.preventDefault
  //         ? event.preventDefault()
  //         : (event.returnValue = false)
  //       homeVideoContainer.classList.toggle('show-video')
  //       // MediaSource.muted=true;
  //     })
  //   })
  //   buttonPlay.addEventListener('click', event => {
  //     event.preventDefault
  //       ? event.preventDefault()
  //       : (event.returnValue = false)
  //     homeVideoContainer.classList.toggle('show-video')
  //   })
  // }
})

// function initIntersectionObserver() {
//   let observer = new IntersectionObserver(
//     entries => {
//       entries.forEach(entry => {
//         document.body.classList.toggle(
//           entry.target.dataset.intersectionBodyClass,
//           entry.isIntersecting
//         )
//       })
//     },
//     {
//       rootMargin: '-100px',
//     }
//   )
//   $$('[data-intersection-body-class]').forEach(element =>
//     observer.observe(element)
//   )
//
//
// }
