const {$, $$} = window // eslint-disable-line

export class Timeline {
  constructor(selector, eventDates) {
    this.timeline = selector
    this.EventDates = eventDates
    let d = new Date()
    let actualDay = d
    this.EventDates.forEach(function(element) {
      let attribute = element.getAttribute('datetime')
      if (Date.parse(attribute) > Date.parse(actualDay)) {
        element.parentNode.classList.add('timeline--future')
      } else {
        element.parentNode.classList.add('timeline--past')
      }
    })

    this.addEventListeners()
  }

  initSlider = () => {
    let getPastEvents = $$('.timeline--past')
    let last_element = getPastEvents.length - 1
    let number = last_element

    const $carousel = $('.timeline__list').flickity({
      prevNextButtons: false,
      pageDots: false,
      initialIndex: number,
    })
    $carousel.on('change.flickity', this.changeSelection)
    this.changeSelection()
  }

  changeSelection = () => {
    let isSelected = document.querySelector('.is-selected')
    let timelineItem = $$('.timeline__item')
    let currentIndex = timelineItem.indexOf(isSelected)
    if (currentIndex % 2 == 0) {
      timelineItem.forEach(function(e) {
        if (timelineItem.indexOf(e) % 2 == 1) {
          e.classList.remove('timeline__item--up')
        }
        if (timelineItem.indexOf(e) % 2 == 0) {
          e.classList.add('timeline__item--up')
        }
      })
    }

    if (currentIndex % 2 == 1) {
      timelineItem.forEach(function(e) {
        if (timelineItem.indexOf(e) % 2 == 0) {
          e.classList.remove('timeline__item--up')
        }
        if (timelineItem.indexOf(e) % 2 == 1) {
          e.classList.add('timeline__item--up')
        }
      })
    }
  }

  addEventListeners = () => {
    document.addEventListener('DOMContentLoaded', this.initSlider)
    window.addEventListener('load', this.initSlider)
    window.addEventListener('resize', () => {
      this.initSlider()
    })
  }
}
